/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";
// import { paginateArray, sortCompare } from '@core/utils/filter'

class MemberService {
  getUserLists(queryParams) {
    const pars = {
      username: queryParams.username,
      email: queryParams.email,
      departmentId: queryParams.departmentId,
      roleId: queryParams.roleId,
      status: queryParams.status,
      pageNumber: queryParams.page,
    };
    return instance
      .get("/api/staff/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          users: response.data.data,
          total: response.data.count,
          code: response.data.code,
          message: response.data.message ? response.data.message : "",
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }

  addUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("roleId", userData.roleId);
    data.append("username", userData.username);
    data.append("fullName", userData.fullName);
    data.append("email", userData.email);
    data.append("password", userData.password);
    // data.append('phone', userData.phone)
    // data.append('birthday', userData.birthday)
    if (userData.call_center_extension) {
      data.append("call_center_extension", userData.call_center_extension);
    }
    data.append("ip", userData.ip);
    data.append("marketId", userData.marketId);
    return instance.post("/api/staff/create", data, { headers: authHeader() });
  }
  createUserMemberList(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("username", userData.username);
    data.append("fullName", userData.fullName);
    data.append("email", userData.email);
    data.append("password", userData.password);
    data.append("phone", userData.phone);
    data.append("birthday", userData.birthday);
    data.append("levelId", userData.levelId);
    data.append("affId", userData.affId);
    data.append("currency", userData.currency);

    return instance.post("/api/User/create", data, { headers: authHeader() });
  }

  getDepartmentLists(queryParams) {
    const pars = {
      name: queryParams.name,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/department/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          departments: response.data.data,
          total: response.data.count,
        };
        return datas;
      });
  }

  getUserDetail(userId) {
    return instance.get(`/api/staff/detail?staffId=${userId}`, {
      headers: authHeader(),
    });
  }

  updatePassword(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("staffId", userData.staffId);
    data.append("password", userData.password);
    return instance.post("/api/staff/reset-password", data, {
      headers: authHeader(),
    });
  }

  updateUser(userData) {
    const FormData = require("form-data");
    const data = new FormData();
    console.log('userData', userData)
    data.append("staffId", userData.staffId);
    data.append("username", userData.username);
    data.append("fullName", userData.fullName);
    data.append("email", userData.email);
    // data.append('phone', userData.phone)
    // data.append('birthday', userData.birthday)
    data.append("roleId", userData.roleId);
    data.append("marketId", userData.marketId);
    data.append("email_verified", userData.email_verified);
    data.append("phone_verified", userData.phone_verified);
    if (userData.call_center_extension) {
      data.append("call_center_extension", userData.call_center_extension);
    }
    data.append("ip", userData.ips);

    // data.append('password', userData.password)
    data.append("stat", userData.status);
    return instance.post("/api/staff/edit", data, { headers: authHeader() });
  }

  addDepartment(departmentData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", departmentData.name);
    data.append("description", departmentData.description);
    return instance.post("/api/department/create", data, {
      headers: authHeader(),
    });
  }

  updateDepartment(departmentData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("departmentId", departmentData.id);
    data.append("name", departmentData.name);
    data.append("description", departmentData.description);
    data.append("status", departmentData.status);
    return instance.post("/api/department/update", data, {
      headers: authHeader(),
    });
  }

  getAuditLoginLists(queryParams) {
    const pars = {
      ip: queryParams.ip,
      platform: queryParams.platform,
      device: queryParams.device,
      device_model: queryParams.device_model,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/audit-login/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          departments: response.data.data,
          total: response.data.count,
        };
        return datas;
      });
  }
  getAuditIpLists(queryParams) {
    const pars = {
      ip: queryParams.ip,
      username: queryParams.username,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/audit-login/ip", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          departments: response.data.data,
          total: response.data.count,
        };
        return datas;
      });
  }
  getAuditNameLists(queryParams) {
    const pars = {
      // ip: queryParams.ip,
      name: queryParams.name,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/audit/name", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          departments: response.data.data,
          total: response.data.count,
        };
        return datas;
      });
  }

  getPlatformLists() {
    return instance.get("/api/audit-login/platform/index", {
      headers: authHeader(),
    });
  }
  getDeviceLists() {
    return instance.get("/api/audit-login/device/index", {
      headers: authHeader(),
    });
  }
  getDeviceModelLists() {
    return instance.get("/api/audit-login/device-model/index", {
      headers: authHeader(),
    });
  }

  // api/audit-login/platform/index
}

export default new MemberService();
