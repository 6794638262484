import axios from "@axios";
import MemberService from "@/libs/member.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getUserLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        MemberService.getUserDetail(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUserMemberList(ctx, id) {
      return new Promise((resolve, reject) => {
        MemberService.createUserMemberList(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        MemberService.addUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        MemberService.updatePassword(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        MemberService.updateUser(userData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getDepartmentLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/user/users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addDepartment(ctx, departmentData) {
      return new Promise((resolve, reject) => {
        MemberService.addDepartment(departmentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateDepartment(ctx, departmentData) {
      return new Promise((resolve, reject) => {
        MemberService.updateDepartment(departmentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAuditLogin(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getAuditLoginLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAuditIp(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getAuditIpLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAuditName(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getAuditNameLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchPlatform(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getPlatformLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDevice(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getDeviceLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDeviceModel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MemberService.getDeviceModelLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
  },
};
