import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function useDepartment() {
	const toast = useToast()
	const membersDepartments = ref(null)

	const perPage = ref(25)
	const totalMembersDepartments = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const nameFilter = ref('')

	const refMembersDepartmentListTable = ref(null)

	const tableColumns = [
		{ key: 'index', label: '#' },
		{ key: 'name', label: i18n.t('Name') },
		{ key: 'description', label: i18n.t('Description') },
		{ key: 'status', label: i18n.t('Status') },
		{ key: 'createdAt', label: i18n.t('Created at') },
		{ key: 'updatedAt', label: i18n.t('Updated at') },
		{ key: 'action', label: i18n.t('Actions') }
	]

	const dataMeta = computed(() => {
		const localItemsCount = refMembersDepartmentListTable.value ? refMembersDepartmentListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalMembersDepartments.value,
		}
	})

	const refetchData = () => {
		refMembersDepartmentListTable.value.refresh()
	}

	watch([currentPage, perPage, nameFilter], () => {
		refetchData()
	})

	const fetchMemberDepartments = (ctx, callback) => {
		store
			.dispatch('app-member/fetchDepartments', {
				perPage: perPage.value,
				page: currentPage.value,
				name: nameFilter.value,
			})
			.then(response => {
				const { departments, total } = response
				callback(departments)
				membersDepartments.value = departments
				totalMembersDepartments.value = total
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching department list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
	}

	const resolveStatus = status => {
		if (status == 1) return { label: i18n.t('Active'), variant: 'success' }
		if (status == 2) return { label: i18n.t('Inactive'), variant: 'danger' }
		return { label: 'None', variant: 'secondary' }
	}

	return {
		tableColumns,
		perPage,
		currentPage,
		totalMembersDepartments,
		dataMeta,
		perPageOptions,
		sortBy,
		isSortDirDesc,
		refMembersDepartmentListTable,

		membersDepartments,
		nameFilter,

		fetchMemberDepartments,
		refetchData,
		resolveStatus,
	}
}