<template>
	<div>
		<b-card
			no-body
			class="border"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<b-col
						cols="12"
						md="6"
					>
						<b-form-group
							label-cols-sm="4"
							label-cols-lg="3"
							content-cols-sm
							content-cols-lg="7"
							:label="$t('Name')"
							label-for="department-name"
						>
							<b-form-input
								id="department-name"
								v-model="nameFilter"
								class="form-control"
								:placeholder="$t('Please enter name')"
							/>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="6"
						class="d-flex align-items-center flex-row-reverse"
					>
						<b-button
							v-if="$can('create','department')"
							variant="primary"
							class="ml-2"
							@click="showAddDepartmentModal()"
						>
							<span class="text-nowrap">
								<feather-icon icon="PlusIcon"/> {{ $t('New') }}
							</span>
						</b-button>
						<b-button
							variant="primary"
							@click="resetFilter()"
						>
							{{ $t('Reset') }}
						</b-button>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<b-card
			no-body
		>
			<b-table
				ref="refMembersDepartmentListTable"
				class="position-relative table-white-space mb-0"
				:items="fetchMemberDepartments"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(name)="data">
					<b-link
              class="text-primary"
            @click="showUpdateDepartmentModal(data.item)"
					>
						{{ data.item.name }}
					</b-link>
				</template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

				<template #cell(createdAt)="data">
					{{ data.item.created_at ? formatDateTime(data.item.created_at).replace('.000000Z', '') : '-'}}
				</template>

				<template #cell(updatedAt)="data">
					{{ data.item.updated_at ? formatDateTime(data.item.updated_at).replace('.000000Z', '') : '-'}}
				</template>

				<template #cell(action)="data">
					<b-link
						v-if="$can('update','department')"
						class="font-weight-bold text-nowrap"
						@click="showUpdateDepartmentModal(data.item)"
					>
						{{ $t('Edit') }}
					</b-link>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalMembersDepartments"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Modal: Add Department -->
		<add-department-modal
			@refetch-data="refetchData"
		/>
		<!-- Modal: Update Department -->
		<update-department-modal
			:department-datas.sync="departmentDatas"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import { formatDateTime } from '@core/utils/filter'
import store from '@/store'
import memberStoreModule from '@/views/apps/member/memberStoreModule'
import useDepartment from './useDepartment'

export default {
	components: {
		AddDepartmentModal: () => import('./AddDepartmentModal.vue'),
		updateDepartmentModal: () => import('./UpdateDepartmentModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
	},
	data() {
		return {
			departmentDatas: null,
		}
	},
	methods: {
		showAddDepartmentModal() {
			this.$bvModal.show('modal-add-department')
		},
		showUpdateDepartmentModal(data) {
			this.departmentDatas = data
			this.$bvModal.show('modal-update-department')
		},
	},
	setup() {
		// Register module
		if (!store.hasModule('app-member')) store.registerModule('app-member', memberStoreModule)
		const {
			tableColumns,
			perPage,
			currentPage,
			totalMembersDepartments,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refMembersDepartmentListTable,
			membersDepartmentsAdd,
			membersDepartments,
			nameFilter,

			fetchMemberDepartments,
			refetchData,
			resolveStatus,
		} = useDepartment()

		const resetFilter = () => {
			nameFilter.value = ''
		}

		return {
			tableColumns,
			perPage,
			currentPage,
			totalMembersDepartments,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refMembersDepartmentListTable,
membersDepartmentsAdd,
			membersDepartments,
			nameFilter,

			formatDateTime,

			resetFilter,
			fetchMemberDepartments,
			refetchData,
			resolveStatus,
		}
	},
}
</script>